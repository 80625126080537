import { FC } from 'react';
import { LayoutMain } from 'components/layouts/layout-main';
import { useMedia } from 'utils/hooks/useMedia';
import { Link } from 'react-router-dom';

const Content = () => {
  return (
    <section className='p-8'>
      <h2 className='mb-2 text-center text-4xl font-bold'>Упс!</h2>
      <p className='text-center text-gray-300'>
        Кажется, у нас нет того, что вы ищите.
      </p>

      <Link to='/' className='btn my-10'>
        На главную
      </Link>
    </section>
  );
};

const BigContent = () => (
  <div className='m-auto'>
    <h1 className='text-center text-7xl font-bold'>404</h1>
    <p className='text-center text-gray-300 '>Страница не найдена</p>
  </div>
);

export const PageNotFound: FC = () => {
  const { tabletLarge } = useMedia();

  return (
    <LayoutMain
      mainComponent={tabletLarge ? <BigContent /> : <Content />}
      sideBarComponent={<Content />}
      navNameTo='На главную'
      navTo='/'
      currentLocationName='404'
    />
  );
};
