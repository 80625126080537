export const LOADING_STATE = {
  UNKNOWN: 'unknown',
  LOADING: 'loading',
  /** закэшированные данные из браузера */
  CACHED: 'cached',
  ERROR: 'error',
  SUCCESS: 'success',
} as const;

export type LoadingStateType =
  (typeof LOADING_STATE)[keyof typeof LOADING_STATE];
