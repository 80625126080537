import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { memberMySelector } from '../member/member-selectors';

export const orgSelector = (state: RootState) => state.organization;

export const organizationsSelector = createSelector(
  orgSelector,
  (org) => org.orgs,
);

export const myOrgDataSelector = createSelector(
  memberMySelector,
  organizationsSelector,
  (memberMy, orgs) => {
    return orgs.find((org) => {
      return org.owners.find(
        (owner) => memberMy?.serialNumber === owner.serialNumber,
      );
    });
  },
);
