import { Grid } from 'antd';

const { useBreakpoint } = Grid;

export function useMedia() {
  /**
   * xs: screen < 576px
   * sm: screen ≥ 576px
   * md: screen ≥ 768px
   * lg: screen ≥ 992px
   * xl: screen ≥ 1200px
   * xxl: screen ≥ 1600px
   */
  const {
    lg: tabletLarge,
  } = useBreakpoint();

  return {
    /**
     * screen ≥ 992px
     */
    tabletLarge,
  };
}
