import { Form, FormInstance } from 'antd';
import { FC } from 'react';

import {
  CategoryField,
  NameField,
  PriceField,
  StartDateTimeField,
  TrophyFields,
} from './fields';

type FormCreateTournamentProps = {
  // TODO: any
  onFinish: (values: any) => void;
  form: FormInstance;
  hasButtonSubmit?: boolean;
};

export const FormCreateTournament: FC<FormCreateTournamentProps> = ({
  onFinish,
  form,
  hasButtonSubmit = true,
}) => {
  return (
    <Form name='formCreateTournament' onFinish={onFinish} form={form} layout='vertical'>
      <NameField />
      <CategoryField />
      <StartDateTimeField />
      <TrophyFields />
      <PriceField />

      {hasButtonSubmit && (
        <button onClick={() => 'tournament-create'} className='btn my-10'>
          Создать турнир
        </button>
      )}
    </Form>
  );
};
