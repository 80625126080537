import { FC } from 'react';
import { Form, Input } from 'antd';

export const LeagueNameField: FC = () => {
  return (
    <Form.Item
      name='leagueName'
      label='Название лиги'
      rules={[
        {
          required: true,
          message: 'Пожалуйста, введите название лиги',
        },
      ]}
    >
      <Input placeholder='Введите название' size='large' />
    </Form.Item>
  );
};
