import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import leagueReducer from './features/league/league-slice';
import memberReducer from './features/member/member-slice';
import organizationReducer from './features/organization/organization-slice';
import tournamentReducer from './features/tournament/tournament-slice';

export const store = configureStore({
  reducer: {
    league: leagueReducer,
    member: memberReducer,
    organization: organizationReducer,
    tournament: tournamentReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
