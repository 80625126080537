import axios, { AxiosResponse } from 'axios';
import type { TGetOneTournamentApi, TTakePartTournamentApi } from 'store/types';
import type { TTournamentResponse } from 'store/apiTypes';
import { API } from 'store/api/api';

type BodyTournamentType = {
  name: string;
  startTime: string;
  category: string;
  trophy: string;
  ratingUpgrade: boolean;
  active: boolean;
  enabled: boolean;
  contribution: number;
};

export type TAddTournament = {
  leagueId: any;
  orgId: any;
  body: BodyTournamentType;
};

export const addTournamentApi = (
  data: TAddTournament,
): Promise<AxiosResponse<TTournamentResponse, any>> => {
  const { leagueId, orgId, body } = data;

  return axios.post(API.addTournament(orgId, leagueId), body);
};

type TGetTournamentsApi = {
  leagueId: string;
  orgId: string;
};

export const getTournamentsApi = ({
  leagueId,
  orgId,
}: TGetTournamentsApi): Promise<AxiosResponse<TTournamentResponse[], any>> => {
  return axios.get(API.showTournaments(orgId, leagueId));
};

export const getOneTournamentApi = ({
  orgId,
  leagueId,
  tournamentId,
}: TGetOneTournamentApi): Promise<
  AxiosResponse<TTournamentResponse[], any>
> => {
  return axios.get(API.showTournament(orgId, leagueId, tournamentId));
};

export const takePartTournamentApi = ({
  orgId,
  leagueId,
  tournamentId,
}: TTakePartTournamentApi) => {
  return axios.post(API.takePartTournament(orgId, leagueId, tournamentId));
};

export const refuseTournamentApi = ({
  orgId,
  leagueId,
  tournamentId,
}: TTakePartTournamentApi) => {
  return axios.post(API.refuseTournament(orgId, leagueId, tournamentId));
};
