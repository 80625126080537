import { Form, FormInstance } from 'antd';
import { FC } from 'react';
import { LeagueCityField } from './fields/LeagueCityField';
import { LeagueNameField } from './fields/LeagueNameField';

type CreateLeagueFormProps = {
  // TODO: any
  onFinish: (values: any) => void;
  form: FormInstance;
};

export const CreateLeagueForm: FC<CreateLeagueFormProps> = ({
  onFinish,
  form,
}) => {
  return (
    <Form onFinish={onFinish} form={form} layout='vertical'>
      <LeagueNameField />
      <LeagueCityField />
    </Form>
  );
};
