export const LoginIcon = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_2091_2759)">
            <path d="M16 8L4 14.9282L4 1.0718L16 8Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_2091_2759">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
