import { Typography } from 'antd';
import { useEffect } from 'react';

export const PlaybookBot = () => {
  useEffect(() => {
    window.location.replace('https://t.me/PlaybookTennis_bot?start=site');
  }, []);

  return (
    <>
      <Typography.Title level={1}>
        PlayBook | Поиск спарринг-партнера по большому теннису
      </Typography.Title>
      <Typography.Paragraph>
        Следуйте подсказкам и я помогу Вам найти с кем поиграть в большой теннис
      </Typography.Paragraph>
      <Typography.Link href='https://t.me/PlaybookTennis_bot'>
        @PlaybookTennis_bot
      </Typography.Link>
    </>
  );
};
