import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addLeagueApi,
  getAllLeagueMembersApi,
  viewsLeagueApi,
  viewsOneLeagueApi,
} from 'store/api/league-api';
import { TAddLeague } from '../../types';

export const addLeagueAsyncAction = createAsyncThunk(
  'league/addLeagueAsyncAction',
  async ({ name, orgId, city }: TAddLeague) => {
    try {
      const response = await addLeagueApi({ name, orgId, city });
      return response.data;
    } catch (error) {
      console.error('addLeagueApi ERROR', error);
    }
  },
);

export const viewsLeagueAsyncAction = createAsyncThunk(
  'league/viewsLeagueAsyncAction',
  async ({ orgId }: { orgId: string }) => {
    try {
      const response = await viewsLeagueApi(orgId);
      return response.data;
    } catch (error) {
      console.error('viewsLeagueAsyncAction ERROR', error);
    }
  },
);

export const viewsOneLeagueAsyncAction = createAsyncThunk(
  'league/viewsLeagueAsyncAction',
  async ({ orgId, leagueId }: { orgId: string; leagueId: string }) => {
    const response = await viewsOneLeagueApi(orgId, leagueId);
    return response.data;
  },
);

type GetAllLeagueMembersAsyncActionArgType = {
  orgId: string;
  leagueId: string;
};

export const getAllLeagueMembersAsyncAction = createAsyncThunk(
  'league/getAllLeagueMembersAsyncAction',
  async ({ orgId, leagueId }: GetAllLeagueMembersAsyncActionArgType) => {
    try {
      return (await getAllLeagueMembersApi(orgId, leagueId)).data;
    } catch (error) {
      console.error('Ошибка в getAllLeagueMembersAsyncAction:', error);
    }
  },
);
