import { RootState } from 'store/store';

export const tournamentSelector = (state: RootState) => state.tournament;

export const tournamentsListSelector = (state: RootState) =>
  tournamentSelector(state).list;

export const tournamentOneSelector = (state: RootState) =>
  tournamentSelector(state).current;

export const tournamentOneSelectorStatus = (state: RootState) =>
  tournamentSelector(state).currentStatus;

export const tournamentLoadingStatusSelector = (state: RootState) =>
  tournamentSelector(state).status;

  export const tournamentEditPointsStatusSelector = (state: RootState) =>
  tournamentSelector(state).editPointsStatus;
