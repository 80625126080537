import 'antd/dist/reset.css';
import './index.css';

import { ConfigProvider, theme } from 'antd';
import axios from 'axios';
import PlaybookBot from 'components/playbook-bot';
import moment from 'moment';
import { FC, Suspense, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from 'shared/constants/local-storage-keys';
import { YM_ID } from 'shared/constants/ym';
import { store } from 'store/store';
import { getItemFromLocaleStorage } from 'utils/local-storage';
import { sendYMetrik } from 'utils/send-y-metrik';
import {
  AddAdmin,
  AddOrg,
  CreateTournamentPage,
  LeagueSingleRate,
  MainPage,
  PageNotFound,
  RootPage,
  TournamentEditPage,
  TournamentPage,
  UserPage,
  LoginPage,
  TournamentListPage,
  Leagues,
  OrganizationsPage,
  Organization,
} from './pages';
import reportWebVitals from './reportWebVitals';
import { CreateSharedTournament } from 'pages/Leagues/CreateSharedTournament';

moment.locale('ru');
moment.utc();

axios.defaults.headers.common['User'] = getItemFromLocaleStorage(
  LOCAL_STORAGE_KEYS.AUTH_USER_TOKEN,
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// Здесь всё, что касается организации
const orgRoutePaths = [
  {
    path: '/organizations',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <OrganizationsPage />
      </Suspense>
    ),
  },
  {
    // Страница организации. Список лиг только текущей орг.
    path: '/organization/:orgId',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Organization />
      </Suspense>
    ),
  },
  {
    // Страница турниров в лиге.
    path: '/organization/:orgId/:leagueId',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <TournamentListPage />,
      </Suspense>
    ),
  },
  {
    path: '/organization/:orgId/:leagueId/rate',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <LeagueSingleRate />
      </Suspense>
    ),
  },
  {
    path: '/organization/:orgId/:leagueId/:tournamentId',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <TournamentPage />,
      </Suspense>
    ),
  },
  {
    path: '/organization/:orgId/:leagueId/:tournamentId/edit',
    element: <TournamentEditPage />,
  },
  {
    path: '/organization/:orgId/:leagueId/tournament-create',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CreateTournamentPage />,
      </Suspense>
    ),
  },
];

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage />,
    children: [
      {
        path: '/',
        element: <MainPage />,
      },
      {
        path: '/profile/:userId',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <UserPage />,
          </Suspense>
        ),
      },
      {
        // Страница лиг. Здесь полность все лиги без привязки к организации.
        path: '/leagues',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Leagues />
          </Suspense>
        ),
      },
      {
        path: '/league/:orgId/:leagueId',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <TournamentListPage />,
          </Suspense>
        ),
      },
      {
        path: '/league/:orgId/:leagueId/rate',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LeagueSingleRate />
          </Suspense>
        ),
      },
      {
        path: '/league/:orgId/:leagueId/tournament-create',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CreateTournamentPage />,
          </Suspense>
        ),
      },
      {
        path: '/create-tournament',
        element: <CreateSharedTournament />,
      },
      {
        path: '/league/:orgId/:leagueId/:tournamentId',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <TournamentPage />,
          </Suspense>
        ),
      },
      {
        path: '/league/:orgId/:leagueId/:tournamentId/edit',
        element: <TournamentEditPage />,
      },
      ...orgRoutePaths,
    ],
  },

  {
    path: '/login',
    element: <LoginPage />,
  },

  // REDIRECT
  {
    path: '/org',
    element: <Navigate to='/leagues' />,
    children: [
      {
        path: '/org/tournaments',
        element: <Navigate to='/leagues' />,
      },
      {
        path: '/org/rating',
        element: <Navigate to='/leagues' />,
      },
    ],
  },

  // DEV
  {
    path: '/dev/add-org',
    element: <AddOrg />,
  },
  {
    path: '/dev/add-admin',
    element: <AddAdmin />,
  },

  // Adv
  {
    path: '/playbook-bot',
    element: <PlaybookBot />,
  },

  // 404
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

const App: FC = () => {
  const windowLocation = useMemo(() => window.location.href, []);

  // Инициализируем яндекс метрику
  useEffect(() => {
    if (process.env.REACT_APP_INIT_ANALYTIC === 'true') {
      (window as any).ym(YM_ID, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
    }
  }, []);

  // Отправляем яндекс метрику при переходе по страницам
  // Яндекс метрика не понимает роутинг.
  useEffect(() => {
    sendYMetrik('hit', windowLocation);
  }, [windowLocation]);

  return <RouterProvider router={router} />;
};

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <App />
    </ConfigProvider>
  </Provider>,
  // </React.StrictMode>,
);

reportWebVitals();
