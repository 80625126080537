import { RootState } from 'store/store';
import { TLeagueResponse } from 'store/apiTypes';
import { createSelector } from '@reduxjs/toolkit';

export const leagueSelector = (state: RootState) => state.league;

export const currentOneLeagueSelector = (
  state: RootState,
): TLeagueResponse | undefined => leagueSelector(state).currentLeague;

export const currentOneLeagueStatusSelector = (state: RootState) =>
  leagueSelector(state).currentLeagueStatus;

export const leagueMembersSelector = (state: RootState) =>
  leagueSelector(state).leagueMembers;

export const leagueMembersSortedByPointsSelector = createSelector(
  leagueMembersSelector,
  (members) =>
    members.length
      ? [...members].sort((a, b) => {
          return b.score - a.score;
        })
      : [],
);
