import { LayoutMain } from 'components/layouts/layout-main';
import { FormCreateTournament } from '../CreateTournament/FormCreateTournament';
import { Form } from 'antd';
import { CreateLeagueForm } from '../CreateLeague/CreateLeagueForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createOrgLeagueTournamentAsync } from 'store/features/tournament/tournament-actions';
import { myOrgDataSelector } from 'store/features/organization/organization-selectors';
import { useNavigate } from 'react-router-dom';

export const CreateSharedTournament = () => {
  const [createLeagueFormInstance] = Form.useForm();
  const [createTournamentFormInstance] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const myOrgData = useAppSelector(myOrgDataSelector);
  const hasShowCreateLeagueForm = !myOrgData?.leagues.length;

  const handleCreateTournament = () => {
    createTournamentFormInstance.submit();

    const createLeagueValues = createLeagueFormInstance.getFieldsValue();
    const createTournamentValues =
      createTournamentFormInstance.getFieldsValue();

    const { name, category, startDateTime, trophy, contribution } =
      createTournamentValues;

    const { leagueCity, leagueName } = createLeagueValues;

    const leagueSubmittable = hasShowCreateLeagueForm
      ? !!leagueCity && !!leagueName
      : true;

    const submittable = name && category && startDateTime && leagueSubmittable;

    if (!submittable) {
      return;
    }

    dispatch(
      createOrgLeagueTournamentAsync({
        leagueName,
        leagueCity,
        name,
        startTime: startDateTime,
        category,
        trophy,
        contribution,
      }),
    ).then((res) => {
      const orgId = res.payload.orgId;
      const leagueSN = res.payload.leagueSN;
      const tournamentSN = res.payload.tournamentData.serialNumber;

      navigate(`/league/${orgId}/${leagueSN}/${tournamentSN}`);
    });
  };

  return (
    <LayoutMain
      mainComponent={
        <div>
          <header className='container sticky top-0 z-10 bg-darkBlue py-6'>
            <h1 className='text-4xl font-bold text-brand'>Создание турнира</h1>
          </header>
          <main className='container'>
            {hasShowCreateLeagueForm && (
              <CreateLeagueForm
                onFinish={() => {}}
                form={createLeagueFormInstance}
              />
            )}
            <FormCreateTournament
              onFinish={() => {}}
              form={createTournamentFormInstance}
              hasButtonSubmit={false}
            />
            <button onClick={handleCreateTournament} className='btn my-10'>
              Создать турнир
            </button>
          </main>
        </div>
      }
    />
  );
};
