import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authVerificationApi,
  AuthVerificationApiType,
} from 'store/api/auth-api';
import { setItemToLocaleStorage } from 'utils/local-storage';
import { LOCAL_STORAGE_KEYS } from 'shared/constants/local-storage-keys';
import { LOADING_STATE } from 'shared/constants/loading-state';
import axios from 'axios';
import { encode } from 'js-base64';
import { viewsMyMemberAsyncAction } from '../member/member-actions';

/**
 * Кнопка логина телеграмма.
 */
export const authVerificationAsync = createAsyncThunk(
  'auth/authVerificationApi',
  async (data: AuthVerificationApiType, { dispatch }) => {
    try {
      const responseAuthVerification = await authVerificationApi(data);

      if (!responseAuthVerification.data) {
        return {
          isAuth: false,
          status: LOADING_STATE.SUCCESS,
        };
      }

      const userBase64 = encode(JSON.stringify(data));
      axios.defaults.headers.common['User'] = userBase64;
      // Сохраняем токен в локалсторедж
      setItemToLocaleStorage(LOCAL_STORAGE_KEYS.AUTH_USER_TOKEN, userBase64);

      // Получаем данные юзера
      dispatch(viewsMyMemberAsyncAction());
    } catch (error) {
      console.error(error);
    }
  },
);
