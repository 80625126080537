import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { viewsOrgAsyncAction } from './organization-actions';
import { TOrganizationsResponse } from 'store/apiTypes';

const initialState: {
  orgs: TOrganizationsResponse,
} = {
  orgs: [],
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: {
    [viewsOrgAsyncAction.pending.type]: (state) => { },

    [viewsOrgAsyncAction.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.orgs = action.payload;
    },
  },
});

export default organizationSlice.reducer;
