import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddOrgOwnerApiType,
  addOrgOwnerApi,
  addOrganizationApi,
  viewsOrganizationApi,
} from 'store/api/organization-api';

export const addOrgAsyncAction = createAsyncThunk(
  'org/addOrgAsyncAction',
  async ({ name }: { name: string }) => {
    try {
      const response = await addOrganizationApi({ name });
      return response.data;
    } catch (error) {
      console.error('addOrganizationApi ERROR', error);
    }
  },
);

export const viewsOrgAsyncAction = createAsyncThunk(
  'org/viewsOrgAsyncAction',
  async () => {
    try {
      const response = await viewsOrganizationApi();
      return response.data;
    } catch (error) {
      console.error('viewsOrgAsyncAction ERROR', error);
    }
  },
);

export const addOrgOwnerAsyncAction = createAsyncThunk(
  'org/addOrgOwnerAsyncAction',
  async ({ orgId, memberSn }: AddOrgOwnerApiType) => {
    try {
      await addOrgOwnerApi({ orgId, memberSn });
    } catch (error) {
      console.error('addOrgOwnerAsyncAction ERROR', error);
    }
  },
);

type AddOrgWithAdminAsyncActionType = { name: string; memberSn: number };

export const addOrgWithAdminAsyncAction = createAsyncThunk<
  void,
  AddOrgWithAdminAsyncActionType
>(
  'org/addOrgWithAdminAsyncAction',
  async ({ name, memberSn }, { dispatch }) => {
    try {
      const addOrgReponse = await dispatch(addOrgAsyncAction({ name }));

      await dispatch(
        addOrgOwnerAsyncAction({
          orgId: addOrgReponse.payload.serialNumber,
          memberSn,
        }),
      );
    } catch (error) {
      console.error('Ошибка в addOrgWithAdminAsyncAction:', error);
    }
  },
);
