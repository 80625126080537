import { Form, Input } from 'antd';

export const CategoryField = () => (
  <Form.Item
    name='category'
    label='Разряд'
    rules={[{ required: true, message: 'Ведите разряд' }]}
  >
    <Input placeholder='Ведите разряд' size='large' />
  </Form.Item>
);
