import axios from 'axios';
import { TAddLeague } from '../types';
import { API } from 'store/api/api';
import { TGetAllLeagueMembersApiResponse } from 'store/apiTypes';

/**
 * Добавить лигу
 */
export const addLeagueApi = ({ name, orgId, city }: TAddLeague) => {
  return axios.post(API.addLeague(orgId), {
    name,
    city,
  });
};

/**
 * Посмотреть лиги
 */
export const viewsLeagueApi = (orgId: string) => {
  return axios.get(API.showLeagues(orgId));
};

export const viewsOneLeagueApi = (orgId: string, leagueId: string) => {
  return axios.get(API.showLeague(orgId, leagueId));
};

/**
 * Посмотреть всех участников лиги
 */
export const getAllLeagueMembersApi = (orgId: string, leagueId: string) => {
  return axios.get<TGetAllLeagueMembersApiResponse[]>(
    API.getAllLeagueMembers(orgId, leagueId),
  );
};
