import Footer from 'components/footer';
import Header from 'components/header';
import { IconArrowLeft } from 'icons';
import { FC, ReactNode, memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { currentOneLeagueSelector } from 'store/features/league/league-selectors';
import { classname } from 'utils/bem-config';
import { useMedia } from 'utils/hooks/useMedia';
import './layout-main.scss';

const cn = classname('layout-main');

type LayoutMainProps = {
  mainComponent: ReactNode;
  sideBarComponent?: ReactNode;
  navTo?: string;
  navNameTo?: string;
  currentLocationName?: string;
};

const Navigation = ({
  navTo = '',
  navNameTo = 'Назад',
  currentLocationName = '',
}) => {
  return (
    <div className='container mb-2 flex items-center justify-between border-b border-gray-700 py-2'>
      {navTo ? (
        <Link
          to={navTo}
          className='flex items-center gap-2 p-2 pl-0 text-gray-400'
        >
          <IconArrowLeft />
          <span>{navNameTo}</span>
        </Link>
      ) : (
        <span></span>
      )}

      <h3 className='text-gray-400 text-right'>{currentLocationName}</h3>
    </div>
  );
};

export const LayoutMain: FC<LayoutMainProps> = memo(
  ({
    sideBarComponent,
    mainComponent,
    navTo,
    navNameTo,
    currentLocationName,
  }) => {
    const { tabletLarge } = useMedia();
    const currentOneLeague = useSelector(currentOneLeagueSelector);
    const currentLeagueName = currentOneLeague?.name || '';
    const locationName =
      currentLocationName === 'LEAGUE'
        ? currentLeagueName
        : currentLocationName;

    return (
      <div className={cn()}>
        {/* Side */}
        {tabletLarge === true && (
          <div className={cn('sidebar')}>
            <Header />
            <Navigation
              navNameTo={navNameTo}
              navTo={navTo}
              currentLocationName={locationName}
            />
            {!!sideBarComponent && sideBarComponent}
            <div className='mt-auto'>
              <Footer />
            </div>
          </div>
        )}

        {/* Main */}
        <main className={cn('main')}>
          {tabletLarge === false && (
            <>
              <Header />
              <Navigation
                navNameTo={navNameTo}
                navTo={navTo}
                currentLocationName={locationName}
              />
            </>
          )}

          {tabletLarge !== undefined && mainComponent}

          {tabletLarge === false && (
            <div className='mt-auto'>
              <Footer />
            </div>
          )}
        </main>
      </div>
    );
  },
);
