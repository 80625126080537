export const ExitIcon = () => (
    <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.5312 6.53125L10.2812 11.7812C10.1354 11.9271 9.95833 12 9.75 12C9.54167 12 9.36458 11.9271 9.21875 11.7812C9.07292 11.6354 9 11.4583 9 11.25V8.25H4.75C4.54167 8.25 4.36458 8.17708 4.21875 8.03125C4.07292 7.88542 4 7.70833 4 7.5V4.5C4 4.29167 4.07292 4.11458 4.21875 3.96875C4.36458 3.82292 4.54167 3.75 4.75 3.75H9V0.75C9 0.541667 9.07292 0.364583 9.21875 0.21875C9.36458 0.0729167 9.54167 0 9.75 0C9.95833 0 10.1354 0.0729167 10.2812 0.21875L15.5312 5.46875C15.6771 5.61458 15.75 5.79167 15.75 6C15.75 6.20833 15.6771 6.38542 15.5312 6.53125ZM6 11.625V10.375C6 10.2708 5.95833 10.1875 5.875 10.125C5.8125 10.0417 5.72917 10 5.625 10H3C2.72917 10 2.48958 9.90625 2.28125 9.71875C2.09375 9.51042 2 9.27083 2 9V3C2 2.72917 2.09375 2.5 2.28125 2.3125C2.48958 2.10417 2.72917 2 3 2H5.625C5.72917 2 5.8125 1.96875 5.875 1.90625C5.95833 1.82292 6 1.72917 6 1.625V0.375C6 0.270833 5.95833 0.1875 5.875 0.125C5.8125 0.0416667 5.72917 0 5.625 0H3C2.16667 0 1.45833 0.291667 0.875 0.875C0.291667 1.45833 0 2.16667 0 3V9C0 9.83333 0.291667 10.5417 0.875 11.125C1.45833 11.7083 2.16667 12 3 12H5.625C5.72917 12 5.8125 11.9688 5.875 11.9062C5.95833 11.8229 6 11.7292 6 11.625Z"
            fill="currentColor"
        />
    </svg>
);
