export const VkIcon = () => (
    <svg
        width="26"
        height="16"
        viewBox="0 0 26 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.4039 1.08352C25.5855 0.458411 25.4039 0 24.5449 0H21.6995C20.9755 0 20.6446 0.396573 20.463 0.83213C20.463 0.83213 19.0162 4.48731 16.9662 6.85733C16.3045 7.54697 16.0021 7.76475 15.6414 7.76475C15.4611 7.76475 15.199 7.54697 15.199 6.92186V1.08352C15.199 0.33339 14.9901 0 14.3867 0H9.91554C9.46401 0 9.19153 0.346833 9.19153 0.677533C9.19153 1.38733 10.2166 1.55134 10.3217 3.55167V7.89111C10.3217 8.84154 10.1569 9.01496 9.79357 9.01496C8.82953 9.01496 6.48364 5.34633 5.09141 1.1467C4.82023 0.330701 4.54646 0.00134486 3.81986 0.00134486H0.975725C0.162188 0.00134486 0 0.397917 0 0.833474C0 1.61586 0.964047 5.48748 4.49067 10.608C6.84175 14.1045 10.1517 16 13.1671 16C14.9745 16 15.1977 15.5792 15.1977 14.8533V12.2104C15.1977 11.3688 15.3703 11.1995 15.9425 11.1995C16.3628 11.1995 17.0869 11.4199 18.7749 13.1044C20.703 15.102 21.0222 15.9987 22.1056 15.9987H24.9497C25.762 15.9987 26.1681 15.5779 25.9345 14.7471C25.6789 13.9203 24.7577 12.7172 23.5355 11.2936C22.8711 10.4816 21.8785 9.6078 21.5762 9.1709C21.1545 8.60763 21.2765 8.35893 21.5762 7.85885C21.5775 7.86019 25.0432 2.80289 25.4039 1.08352Z"
            fill="currentColor"
        />
    </svg>
);
