import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { classname } from 'utils/bem-config';
import { Login } from 'components/login';
import { ExitIcon } from './images/exit_16';
import logoBlank from './images/logo_blank.svg';
import './header.scss';
import { memberMySelector } from 'store/features/member/member-selectors';
import { LOCAL_STORAGE_KEYS } from 'shared/constants/local-storage-keys';
import { clearMyMemberAction } from 'store/features/member/member-slice';

const bem = classname('header');

type HeaderUserType = {
  userName: string;
  avatarSrc?: string;
};

const HeaderUser: FC<HeaderUserType> = ({ avatarSrc, userName }) => {
  const dispatch = useAppDispatch();
  const myProfile = useAppSelector(memberMySelector);

  const onLogout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_USER_TOKEN);
    dispatch(clearMyMemberAction());
  };

  return (
    <div className={bem('user')}>
      <img
        className={
          bem('userPhoto') +
          ' border border-solid border-black bg-lg-blue-orange'
        }
        src={avatarSrc || logoBlank}
        alt=''
      />
      <div className={bem('userInfo')}>
        <Link
          className={bem('userName')}
          to={`/profile/${myProfile?.serialNumber}`}
        >
          {userName}
        </Link>
        <div className={bem('userType')}>Теннисист</div>
      </div>
      <button
        className={bem('userExitButton')}
        title='Выйти'
        onClick={onLogout}
      >
        <ExitIcon />
      </button>
    </div>
  );
};

export const Header: FC = () => {
  const member = useAppSelector(memberMySelector);
  const userName = `${member?.firstName || 'Ваше имя'} ${member?.lastName || ''
    }`;

  return (
    <header className={bem()}>
      <div className={bem('header-wrap')}>
        {!member && <Login />}
        {member && (
          <HeaderUser avatarSrc={member.photoUrl} userName={userName} />
        )}
      </div>
    </header>
  );
};
