import { lazy } from 'react';

export const TournamentListPage = lazy(() => import('./TournamentListPage'));
export const Leagues = lazy(() => import('./LeaguesPage'));
export const OrganizationsPage = lazy(() => import('./OrganizationsPage'));
export const Organization = lazy(() => import('./Organization'));
export const UserPage = lazy(() => import('./UserPage'));
export const CreateTournamentPage = lazy(
  () => import('./CreateTournament/CreateTournamentPage'),
);
export const TournamentPage = lazy(() => import('./TournamentPage'));
export const LeagueSingleRate = lazy(() => import('./LeagueSingleRate'));

export { LeagueNav, SidebarLeagueNav } from './LeagueNav';
export { TournamentEditPage } from './TournamentEditPage';
export { UserItemPoints, UserItem } from './UserItem';
