import { FC } from 'react';
import { Form, Input } from 'antd';

export const LeagueCityField: FC = () => {
  return (
    <Form.Item
      name='leagueCity'
      label='Город'
      rules={[
        {
          required: true,
          message: 'Пожалуйста, введите название город',
        },
      ]}
    >
      <Input placeholder='Введите город' size='large' />
    </Form.Item>
  );
};
