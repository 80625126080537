import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Form, InputNumber } from 'antd';
import clsx from 'clsx';
import { TMemberApi } from 'store/apiTypes';
// TODO: Открыть, когда будет определяться пол
// import { IconFemale, IconMale } from 'icons';
import { TGetAllLeagueMembersApiResponse } from 'store/apiTypes';

type IUserItemPoints = TGetAllLeagueMembersApiResponse & { order: number };

const UserPhoto = ({ photoUrl = '' }: { photoUrl?: string }) =>
  photoUrl ? (
    <img
      className='h-9 w-9 rounded-full bg-lg-blue-orange'
      src={photoUrl}
      alt=''
    />
  ) : (
    <div className='h-9 w-9 rounded-full bg-lg-blue-orange opacity-50' />
  );

export const UserItemPoints: FC<IUserItemPoints> = ({
  order,
  score,
  member: { firstName, lastName, photoUrl },
}) => {
  return (
    <div className='flex items-center gap-3 border-b border-gray-800 py-2 last:border-none'>
      <UserPhoto photoUrl={photoUrl} />

      <div className='flex-1'>
        <p className='flex items-center gap-1'>
          {/* TODO: Открыть, когда будет определяться пол */}
          {/* {data.gender === 'male' ? <IconMale /> : <IconFemale />} */}

          <span>
            {firstName} {lastName}
          </span>

          {order === 1 && <span> 🥇</span>}
          {order === 2 && <span> 🥈</span>}
          {order === 3 && <span> 🥉</span>}
          {order > 3 && <span className='text-gray-600'> #{order}</span>}
        </p>
        {/* TODO: Открыть когда у юзера будут города */}
        {/* <p className='text-sm text-gray-400'>Москва</p> */}
      </div>

      <div>
        <span
          className={clsx('text-xl font-bold', {
            'text-[#FBCF0F]': order === 1,
            'text-[#D6D6D6]': order === 2,
            'text-[#AC4E13]': order === 3,
          })}
        >
          {score}
        </span>
        <span className='text-xs font-extralight text-gray-400'> оч</span>
      </div>

      {/* <span>{data.tournaments}</span> */}
    </div>
  );
};

export const UserItem = ({ data }: { data: TMemberApi }) => {
  const user = data.member;

  return (
    <div className='flex items-center gap-3 border-b border-gray-800 py-2 last:border-none'>
      <UserPhoto photoUrl={user.photoUrl} />

      <div className='flex-1'>
        <p className='flex items-center gap-1'>
          {/* {data.gender === 'male' ? <IconMale /> : <IconFemale />} */}
          <Link to={`/profile/${user.serialNumber}`}>
            {user.firstName || ''} {user.lastName || ''}
          </Link>
        </p>
        <p className='flex justify-between text-sm text-gray-400'>
          <span>Москва</span>
          <span>{data.score} оч.</span>
        </p>
      </div>
    </div>
  );
};

export const UserItemEditPoints = ({ data }: { data: TMemberApi }) => {
  const user = data.member;

  return (
    <div className='flex items-center gap-3 border-b border-gray-800 py-2 last:border-none'>
      <img
        className='h-9 w-9 rounded-full bg-lg-blue-orange'
        src={user.photoUrl}
        alt=''
      />

      <div className='flex-1'>
        <p className='flex items-center gap-1'>
          {/* {data.gender === 'male' ? <IconMale /> : <IconFemale />} */}
          <span>
            {user.firstName || ''} {user.lastName || ''}
          </span>
        </p>
        <p className='flex justify-between text-sm text-gray-400'>
          <span>Москва</span>
        </p>
      </div>

      <Form.Item name={user.serialNumber} className='m-0'>
        <InputNumber size='large' min={0} max={1000} type='number' />
      </Form.Item>
    </div>
  );
};
