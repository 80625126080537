export const API_URL = process.env.REACT_APP_API_URL || '';

type T_API = { [a: string]: (...args: string[]) => string };

export const API: T_API = {
  // authTg: (telegramId) => `${API_URL}/auth/${telegramId}`,
  auth: () => `${API_URL}/auth`,

  addOrganization: () => `${API_URL}/admin/organization/`,
  addOrganizationOwner: (orgId) =>
    `${API_URL}/admin/organization/${orgId}/owner`,

  addLeague: (orgId) => `${API_URL}/organization/${orgId}/league`,
  addTournament: (orgId, leagueId) =>
    `${API_URL}/organization/${orgId}/league/${leagueId}/tournament`, // добавить

  showOrganization: () => `${API_URL}/public/organization/`,
  showLeagues: (orgId) => `${API_URL}/public/organization/${orgId}/league`,
  showLeague: (orgId, leagueId) =>
    `${API_URL}/public/organization/${orgId}/league/${leagueId}`,
  getAllLeagueMembers: (orgId, leagueId) =>
    `${API_URL}/public/organization/${orgId}/league/${leagueId}/members`,
  showTournaments: (orgId, leagueId) =>
    `${API_URL}/public/organization/${orgId}/league/${leagueId}/tournament`, // посмотреть
  showTournament: (orgId, leagueId, tournamentId) =>
    `${API_URL}/public/organization/${orgId}/league/${leagueId}/tournament/${tournamentId}`,

  takePartTournament: (orgId, leagueId, tournamentId) =>
    `${API_URL}/organization/${orgId}/league/${leagueId}/tournament/${tournamentId}/signup`,
  refuseTournament: (orgId, leagueId, tournamentId) =>
    `${API_URL}/organization/${orgId}/league/${leagueId}/tournament/${tournamentId}/refuse`,
  editTournamentPoints: (orgId, leagueId, tournamentId) =>
    `${API_URL}/organization/${orgId}/league/${leagueId}/tournament/${tournamentId}/score`, // PUT

  showMembers: () => `${API_URL}/admin/member`,
  showMember: (memberId) => `${API_URL}/public/member/${memberId}`,
  showMyMember: () => `${API_URL}/public/member`,
};
