import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from 'store/api/api';
import {
  addTournamentApi,
  TAddTournament,
  getTournamentsApi,
  getOneTournamentApi,
  takePartTournamentApi,
  refuseTournamentApi,
} from 'store/api/tournament-api';
import type {
  TAddLeague,
  TGetOneTournamentApi,
  TTakePartTournamentApi,
} from 'store/types';
import { addLeagueAsyncAction } from '../league/league-actions';
import { myOrgDataSelector } from '../organization/organization-selectors';
import { RootState } from 'store/store';
import { memberMySelector } from '../member/member-selectors';

export const addTournamentAsync = createAsyncThunk(
  'tournament/addTournamentAsync',
  async (data: TAddTournament) => {
    try {
      const response = await addTournamentApi(data);
      return response.data;
    } catch (err) {
      console.error('addTournamentAsyncError', err);
    }
  },
);

export const getTournamentsAsync = createAsyncThunk(
  'tournament/getTournamentsAsync',
  async ({ leagueId, orgId }: { leagueId: string; orgId: string }) => {
    try {
      const response = await getTournamentsApi({ leagueId, orgId });
      return response.data;
    } catch (e) {
      console.error('getTournamentsAsyncError', e);
    }
  },
);

export const getOneTournamentAsync = createAsyncThunk(
  'tournament/getOneTournamentAsync',
  async ({ leagueId, orgId, tournamentId }: TGetOneTournamentApi) => {
    const response = await getOneTournamentApi({
      leagueId,
      orgId,
      tournamentId,
    });
    return response.data;
  },
);

export const takePartTournamentAsync = createAsyncThunk(
  'tournament/takePartTournamentAsync',
  async ({ leagueId, orgId, tournamentId }: TTakePartTournamentApi) => {
    const response = await takePartTournamentApi({
      leagueId,
      orgId,
      tournamentId,
    });

    return response.data;
  },
);

export const refuseTournamentAsync = createAsyncThunk<
  any,
  TTakePartTournamentApi & { memberId: string }
>(
  'tournament/refuseTournamentAsync',
  async ({ leagueId, orgId, tournamentId, memberId }) => {
    try {
      const response = await refuseTournamentApi({
        leagueId,
        orgId,
        tournamentId,
      });

      return {
        data: response.data,
        memberId,
      };
    } catch (error) {
      console.error('Ошибка в refuseTournamentAsync:', error);
      throw error;
    }
  },
);

export const editTournamentPointsAsync = createAsyncThunk(
  'tournament/editTournamentPointsAsync',
  async ({
    leagueId,
    orgId,
    tournamentId,
    data,
  }: TTakePartTournamentApi & { data: any }) =>
    (
      await axios.put(
        API.editTournamentPoints(orgId, leagueId, tournamentId),
        data,
      )
    ).data,
);

type ArgsType = Omit<
  TAddTournament['body'],
  'ratingUpgrade' | 'active' | 'enabled'
> & {
  leagueName?: TAddLeague['name'];
  leagueCity?: TAddLeague['city'];
};

export const createOrgLeagueTournamentAsync = createAsyncThunk<
  any,
  ArgsType,
  {
    state: RootState;
  }
>(
  'tournament/createOrgLeagueTournamentAsync',
  async (
    { leagueName, leagueCity, name, startTime, category, trophy, contribution },
    { dispatch, getState },
  ) => {
    try {
      const state = getState();
      let myOrgData = myOrgDataSelector(state);
      const memberMy = memberMySelector(state);

      if (!myOrgData || !memberMy) {
        return;
      }

      const orgId = `${myOrgData.serialNumber}`;

      // Создаем лигу, если это требуется
      if (leagueName && leagueCity) {
        const leagueResponse = await dispatch(
          addLeagueAsyncAction({
            orgId,
            name: leagueName,
            city: leagueCity,
          }),
        );

        myOrgData = {
          ...myOrgData,
          leagues: [leagueResponse.payload],
        };
      }

      // Пока может быть только одна лига у организации и у юзера
      const leagueData = myOrgData.leagues[0];

      const tournamentData = await dispatch(
        addTournamentAsync({
          leagueId: leagueData.serialNumber,
          orgId,
          body: {
            name,
            startTime,
            category,
            trophy,
            ratingUpgrade: true,
            active: true,
            enabled: true,
            contribution: Number(contribution),
          },
        }),
      );

      return {
        orgId,
        leagueSN: leagueData.serialNumber,
        tournamentData: tournamentData.payload,
      };
    } catch (error) {
      console.error('Ошибка в createOrgLeagueTournamentAsync:', error);
      throw error;
    }
  },
);
