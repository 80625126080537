import { Form, message } from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { memberMySelector } from 'store/features/member/member-selectors';
import {
  editTournamentPointsAsync,
  getOneTournamentAsync,
} from 'store/features/tournament/tournament-actions';
import {
  tournamentEditPointsStatusSelector,
  tournamentOneSelector,
  tournamentOneSelectorStatus,
} from 'store/features/tournament/tournament-selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LayoutMain } from '../../components/layouts/layout-main';
import logo from '../hello/logo.svg';
import { UserItemEditPoints } from './UserItem';

export const TournamentEditPage = () => {
  const dispatch = useAppDispatch();
  const currentTournamentId = useParams().tournamentId || '0';
  const currentLeagueId = useParams().leagueId || '0';
  const currentOrgId = useParams().orgId || '0';
  const tournamentOneData = useAppSelector(tournamentOneSelector);
  const tournamentOneStatus = useAppSelector(tournamentOneSelectorStatus);
  const tournamentEditPointsStatus = useAppSelector(
    tournamentEditPointsStatusSelector,
  );
  const [messageApi, contextHolder] = message.useMessage();
  const member = useAppSelector(memberMySelector);
  // const momentData = moment(tournamentOneData?.startTime);

  useEffect(() => {
    !tournamentOneData &&
      dispatch(
        getOneTournamentAsync({
          leagueId: currentLeagueId,
          orgId: currentOrgId,
          tournamentId: currentTournamentId,
        }),
      );
  }, [
    dispatch,
    currentLeagueId,
    currentTournamentId,
    currentOrgId,
    tournamentOneData,
  ]);

  const onFinishHandle = (values: any) => {
    const result = [];
    for (const keySN in values) {
      result.push({
        score: values[keySN],
        member: {
          serialNumber: keySN,
        },
      });
    }

    dispatch(
      editTournamentPointsAsync({
        data: result,
        leagueId: currentLeagueId,
        orgId: currentOrgId,
        tournamentId: currentTournamentId,
      }),
    ).unwrap().then(() => messageApi.success('Сохранено')).catch(() => messageApi.error('Ошибка'));
  };

  const initialValue = () =>
    tournamentOneData?.members.reduce(
      (acc, val) => ({ ...acc, [val.member.serialNumber]: val.score }),
      {},
    );

  return (
    <LayoutMain
      mainComponent={
        <>
          {/* Всплывашка */}
          {contextHolder}

          {tournamentOneStatus === 'loading' && (
            <div className='container'>Загрузка</div>
          )}
          {tournamentOneStatus === 'error' && (
            <div className='container'>Такого турнира не существует</div>
          )}
          {tournamentOneStatus === 'success' && (
            <div className='py-8 max-lg:px-2'>
              <header className='container sticky top-0 z-10 border-b border-gray-700 bg-darkBlue pb-6 pt-1'>
                <div className='mb-3 flex items-start gap-4'>
                  <h1 className='flex-1 text-3xl font-bold'>
                    {tournamentOneData?.name}
                  </h1>
                </div>
              </header>

              <main className=' container py-6'>
                <Form onFinish={onFinishHandle} initialValues={initialValue()}>
                  {tournamentOneData?.members.map((el, i) => (
                    <UserItemEditPoints data={el} key={i} />
                  ))}

                  {!tournamentOneData?.members?.[0] && (
                    <span>Участников нет</span>
                  )}

                  {/* Участвовать в турнире */}
                  {member ? (
                    <Form.Item>
                      <button
                        type='submit'
                        className='btn mt-6'
                        disabled={tournamentEditPointsStatus === 'loading'}
                      >
                        Сохранить
                      </button>
                    </Form.Item>
                  ) : (
                    <div className='mt-6'>
                      <Link to='/login' className='text-hoverBlue'>
                        Войдите! Чтобы редактировать.
                      </Link>
                    </div>
                  )}
                </Form>
              </main>
            </div>
          )}
        </>
      }
      sideBarComponent={
        <div className='mb-20 flex flex-1'>
          <img className='m-auto' src={logo} alt='Playbook - большой теннис' />
        </div>
      }
      navNameTo='Назад'
      navTo={`/league/${currentOrgId}/${currentLeagueId}/${currentTournamentId}`}
      currentLocationName='LEAGUE'
    />
  );
};
