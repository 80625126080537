const LOCALE_STORAGE_TEST_DATA = {
  key: 'e9270246ae5f0748',
  value: 'e9270246ae5f0748',
};

const getStorage = (session?: boolean) =>
  session ? window.sessionStorage : window.localStorage;

let memoizedIsLocalStorageAvailable = false;

const isLocalStorageAvailable = (
  testData: typeof LOCALE_STORAGE_TEST_DATA,
  session?: boolean
) => {
  try {
    if (memoizedIsLocalStorageAvailable) {
      return memoizedIsLocalStorageAvailable;
    }

    const storage = getStorage(session);

    storage.setItem(testData.key, testData.value);
    storage.removeItem(testData.key);

    memoizedIsLocalStorageAvailable = true;

    return memoizedIsLocalStorageAvailable;
  } catch (e) {
    memoizedIsLocalStorageAvailable = false;

    return memoizedIsLocalStorageAvailable;
  }
};

export function getItemFromLocaleStorage<T>(
  key: string,
  session?: boolean
): T | null {
  let value: T | null;

  if (isLocalStorageAvailable(LOCALE_STORAGE_TEST_DATA)) {
    try {
      value = JSON.parse(getStorage(session).getItem(key) || 'null');
    } catch (e) {
      value = null;
    }

    return value;
  }

  return null;
}

// eslint-disable-next-line consistent-return
export const setItemToLocaleStorage = (
  key: string,
  value: unknown,
  session?: boolean
) => {
  if (isLocalStorageAvailable(LOCALE_STORAGE_TEST_DATA)) {
    return getStorage(session).setItem(key, JSON.stringify(value));
  }
};

export const generateLocalStorageKey = (...strings: string[]) =>
  [...strings].filter(Boolean).join('|');
