import { Button, Form, Input } from 'antd';
import { addOrgOwnerAsyncAction } from 'store/features/organization/organization-actions';
import { useAppDispatch } from 'store/hooks';

export const AddAdmin = () => {
  const dispatch = useAppDispatch();

  const onFinishHandle = (values: any) => {
    dispatch(
      addOrgOwnerAsyncAction({
        orgId: values.orgSn,
        memberSn: +values.memberSn,
      }),
    );
  };

  return (
    <div>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        name='add-admin'
        onFinish={onFinishHandle}
      >
        <Form.Item
          label='SN организации'
          name='orgSn'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='SN юзера для добавления'
          name='memberSn'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button htmlType='submit'>Добавить админа организации</Button>
        </Form.Item>
      </Form>
    </div>
  );
};
