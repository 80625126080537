export const TgIcon = () => (
    <svg
        width="21"
        height="16"
        viewBox="0 0 21 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.44343 6.88797C7.08164 4.63021 10.8404 3.14161 12.7198 2.42245C18.0922 0.368853 19.2071 0.0121885 19.9353 0.000105283C20.0954 -0.00225581 20.452 0.0341325 20.6846 0.207048C20.878 0.35288 20.9324 0.550101 20.9596 0.688573C20.9838 0.826905 21.017 1.14218 20.9898 1.38829C20.6997 4.19938 19.4398 11.021 18.7992 14.1696C18.5303 15.5018 17.9955 15.9485 17.4788 15.9921C16.3548 16.0871 15.5027 15.3099 14.4149 14.6546C12.7138 13.6288 11.7529 12.9904 10.1002 11.9896C8.19054 10.8329 9.42938 10.1971 10.5171 9.15823C10.8012 8.88629 15.7505 4.74882 15.8441 4.37355C15.8562 4.3266 15.8683 4.1516 15.7535 4.05938C15.6417 3.96688 15.4755 3.99855 15.3546 4.02355C15.1824 4.0591 12.466 5.71131 7.19646 8.9799C6.42596 9.46712 5.72798 9.70462 5.0995 9.69212C4.41059 9.67851 3.08111 9.33323 2.09306 9.03823C0.884445 8.67629 -0.0794292 8.4849 0.00517413 7.87019C0.0474758 7.55019 0.527902 7.22269 1.44343 6.88797V6.88797Z"
            fill="currentColor"
        />
    </svg>
);
