import { FC, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from 'shared/constants/local-storage-keys';
import { viewsOneLeagueAsyncAction } from 'store/features/league/league-actions';
import { viewsMyMemberAsyncAction } from 'store/features/member/member-actions';
import { viewsOrgAsyncAction } from 'store/features/organization/organization-actions';
import { getTournamentsAsync } from 'store/features/tournament/tournament-actions';
import { useAppDispatch } from 'store/hooks';
import { getItemFromLocaleStorage } from 'utils/local-storage';

export const RootPage: FC = () => {
  const dispatch = useAppDispatch();
  const currentLeagueIdPage = useParams().leagueId || false;
  const currentOrgId = useParams().orgId || false;
  // const currentTournamentId = useParams().tournamentId || '0';

  useEffect(() => {
    const isAuth = getItemFromLocaleStorage(LOCAL_STORAGE_KEYS.AUTH_USER_TOKEN);
    if (isAuth) {
      dispatch(viewsMyMemberAsyncAction());
    }

    // Получаем организации
    dispatch(viewsOrgAsyncAction());
  }, [dispatch]);

  useEffect(() => {
    if (currentLeagueIdPage && currentOrgId) {
      // Получаем турниры
      dispatch(
        getTournamentsAsync({
          leagueId: currentLeagueIdPage,
          orgId: currentOrgId,
        }),
      );

      // Получаем текущую лигу
      dispatch(
        viewsOneLeagueAsyncAction({
          orgId: currentOrgId,
          leagueId: currentLeagueIdPage,
        }),
      );
    }
  }, [dispatch, currentLeagueIdPage, currentOrgId]);

  return <Outlet />;
};
