import { Login } from 'components/login';
import { useLocation, useNavigate } from 'react-router-dom';
import { memberMySelector } from 'store/features/member/member-selectors';
import { useAppSelector } from 'store/hooks';
import bgImg from './login.jpg';
import { useEffect } from 'react';

export const LoginPage = () => {
  const member = useAppSelector(memberMySelector);
  const navigate = useNavigate();
  const location = useLocation();
  const title: string = location.state?.loginTitle || 'Войти';
  const redirectTo = location.state?.redirectTo;

  useEffect(() => {
    if (redirectTo && member) {
      navigate(redirectTo);
    }
  }, [redirectTo, member, navigate]);

  return (
    <div
      className='flex h-full bg-cover bg-center'
      style={{ backgroundImage: `url("${bgImg}")` }}
    >
      <div className='m-auto'>
        {!member && (
          <div className='text-center'>
            <h1 className='text-3xl font-bold'>{title}</h1>
            <p className='mb-8 text-gray-300'>При помощи Telegram</p>
            <Login />
          </div>
        )}
        {member && <p className='font-medium'>Вы уже вошли в систему</p>}

        <button
          className='my-10 w-full hover:text-hoverBlue'
          onClick={() => navigate(-1)}
        >
          Назад
        </button>
      </div>
    </div>
  );
};
