import axios from 'axios';
import { API } from 'store/api/api';

type AddOrgApiType = {
  name: string;
};

/**
 * Добавить организацию
 */
export const addOrganizationApi = ({ name }: AddOrgApiType) => {
  return axios.post(
    API.addOrganization(),
    {
      name,
    },
  );
};

/**
 * Посмотреть организации
 */
export const viewsOrganizationApi = () => {
  return axios.get(API.showOrganization());
};

export type AddOrgOwnerApiType = {
  /**
   * Серийный номер организации
   */
  orgId: string;

  /**
   * Серийный номер пользователя
   */
  memberSn: number;
};

// Добавить валдельца организации
export const addOrgOwnerApi = ({ orgId, memberSn }: AddOrgOwnerApiType) => {
  return axios.post(
    API.addOrganizationOwner(orgId),
    {},
    {
      params: { member_sn: memberSn },
    },
  );
};

// Получить валдельца организации
// TODO