import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from 'store/api/api';

export const viewsMemberAsyncAction = createAsyncThunk(
  'member/viewsMemberAsyncAction',
  async ({ memberId }: { memberId: string }) => {
    const response = await axios.get(API.showMember(memberId));

    return response.data;
  },
);

export const viewsMyMemberAsyncAction = createAsyncThunk(
  'member/viewsMyMemberAsyncAction',
  async () => {
    const response = await axios.get(API.showMyMember());
    return response.data;
  },
);
