import { DatePicker, Form } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';

export const StartDateTimeField = () => (
  <Form.Item
    name='startDateTime'
    label='Дата и время начала'
    rules={[{ required: true, message: 'Пожалуйста, выберите дату и время' }]}
  >
    <DatePicker
      locale={locale}
      showTime
      format='DD.MM.YYYY HH:mm'
      style={{ width: '100%' }}
      placeholder='Выберите дату и время'
      size='large'
    />
  </Form.Item>
);
