import { Form, Input } from 'antd';

export const NameField = () => {
  return (
    <Form.Item
      name='name'
      label='Название турнира'
      rules={[
        {
          required: true,
          message: 'Пожалуйста, введите название турнира',
        },
      ]}
    >
      <Input placeholder='Введите название' size='large' />
    </Form.Item>
  );
};
