import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  viewsMemberAsyncAction,
  viewsMyMemberAsyncAction,
} from './member-actions';
import {
  LOADING_STATE,
  type LoadingStateType,
} from 'shared/constants/loading-state';
import { TMember } from 'store/apiTypes';

const initialState: {
  members?: TMember[];
  currentMember?: TMember;
  myMember?: TMember;
  currentMemberStatus: LoadingStateType;
} = {
  members: undefined,
  currentMember: undefined,
  myMember: undefined,
  currentMemberStatus: LOADING_STATE.UNKNOWN,
};

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    clearMyMember: (state) => {
      state.myMember = initialState.myMember;
    },
  },
  extraReducers: {
    [viewsMemberAsyncAction.pending.type]: (state) => {
      state.currentMemberStatus = LOADING_STATE.LOADING;
    },
    [viewsMemberAsyncAction.fulfilled.type]: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.currentMember = action.payload;
      state.currentMemberStatus = LOADING_STATE.SUCCESS;
    },

    [viewsMyMemberAsyncAction.fulfilled.type]: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.myMember = action.payload;
    },
  },
});

export const { clearMyMember: clearMyMemberAction } = memberSlice.actions;

export default memberSlice.reducer;
