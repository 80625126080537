import { LoginButton } from '@telegram-auth/react';
import { useAppDispatch } from 'store/hooks';
import { TelegramAuthData } from '@telegram-auth/react';
import { authVerificationAsync } from 'store/features/auth/auth-actions';

const BOT_USER_NAME = process.env.REACT_APP_BOT_USER_NAME || '';

export const Login = () => {
  const dispatch = useAppDispatch();

  const onAuth = (data: TelegramAuthData) => {
    const { id, first_name, auth_date, hash, last_name, photo_url, username } =
      data;

    dispatch(
      authVerificationAsync({
        id: String(id),
        firstName: first_name,
        authDate: auth_date,
        hash,
        lastName: last_name || '',
        photoUrl: photo_url || '',
        username: username || '',
      }),
    );
  };

  return (
    <div className='flex justify-center'>
      <LoginButton
        botUsername={BOT_USER_NAME}
        buttonSize='large'
        lang='ru'
        onAuthCallback={onAuth}
        cornerRadius={8}
      />
    </div>
  );
};
