import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAllLeagueMembersAsyncAction,
  viewsLeagueAsyncAction,
  viewsOneLeagueAsyncAction,
} from './league-actions';
import {
  LOADING_STATE,
  type LoadingStateType,
} from 'shared/constants/loading-state';
import { TGetAllLeagueMembersApiResponse } from 'store/apiTypes';

const initialState: {
  orgs: any;
  currentLeague: any;
  currentLeagueStatus: LoadingStateType;
  leagueMembers: TGetAllLeagueMembersApiResponse[];
} = {
  orgs: [],
  currentLeague: undefined,
  currentLeagueStatus: LOADING_STATE.UNKNOWN,
  leagueMembers: [],
};

export const leagueSlice = createSlice({
  name: 'league',
  initialState,
  reducers: {},
  extraReducers: {
    [viewsLeagueAsyncAction.pending.type]: (state) => {},
    [viewsLeagueAsyncAction.fulfilled.type]: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.orgs = action.payload;
    },

    [viewsOneLeagueAsyncAction.pending.type]: (state) => {
      state.currentLeagueStatus = LOADING_STATE.LOADING;
    },
    [viewsOneLeagueAsyncAction.fulfilled.type]: (
      state,
      action: PayloadAction<any>,
    ) => {
      state.currentLeague = action.payload;
      state.currentLeagueStatus = LOADING_STATE.SUCCESS;
    },

    [getAllLeagueMembersAsyncAction.fulfilled.type]: (
      state,
      action: PayloadAction<TGetAllLeagueMembersApiResponse[]>,
    ) => {
      state.leagueMembers = action.payload;
    },
  },
});

export default leagueSlice.reducer;
