import { clsx } from 'clsx';
import 'moment/locale/ru';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { orgSelector } from 'store/features/organization/organization-selectors';
import { twMerge } from 'tailwind-merge';
import { useIsOrgRoutePath } from 'utils/hooks/useIsOrgRoutePath';

type TOrgs = {
  orgs: {
    serialNumber: number;
    name: string;
    leagues: {
      serialNumber: number;
      name: string;
      city: string;
    }[];
  }[];
};

const LeagueItem = ({
  data: { leagueName, city, organizationName, leagueId, orgId },
  isActive,
}: any) => {
  const isOrgRoutePath = useIsOrgRoutePath();
  const routePath = isOrgRoutePath ? 'organization' : 'league';

  return (
    <Link
      to={`/${routePath}/${orgId}/${leagueId}`}
      className={twMerge(
        clsx(
          `mx-4 rounded-lg p-4 odd:bg-lg-orange even:bg-lg-blue
        hover:text-red-100`,
          {
            'mx-0 rounded-none px-8': isActive,
          },
        ),
      )}
    >
      <p className='mb-1 text-xl font-bold'>{leagueName}</p>
      <p className='mb-2 text-xs font-bold'>{city}</p>
      <p className='text-xs font-normal'>{organizationName}</p>
    </Link>
  );
};

export const LeagueNav = ({
  currentIdPage,
}: {
  currentIdPage: string | number;
}) => {
  const orgs: TOrgs = useSelector(orgSelector);
  const { orgId } = useParams();
  const isOrgRoutePath = useIsOrgRoutePath();

  const currentOrgLeague = orgs.orgs.find(
    (item) => item.serialNumber === Number(orgId),
  );

  const orgsData = isOrgRoutePath
    ? currentOrgLeague
      ? [currentOrgLeague]
      : []
    : orgs.orgs;

  return (
    <nav className='flex flex-col gap-2 pb-20'>
      {Array.isArray(orgsData) && orgsData?.length > 0 ? (
        orgsData.map((orgElement, i) => {
          const orgName = orgElement.name;
          const orgId = orgElement.serialNumber;

          return orgElement.leagues.map((ligElement, i) => {
            return (
              <LeagueItem
                data={{
                  leagueName: ligElement.name,
                  city: ligElement.city,
                  organizationName: orgName,
                  leagueId: ligElement.serialNumber,
                  orgId,
                }}
                key={i}
                isActive={
                  Number(currentIdPage) === Number(ligElement.serialNumber)
                }
              />
            );
          });
        })
      ) : (
        <div className='p-4'>Загрузка ...</div>
      )}
    </nav>
  );
};

export const SidebarLeagueNav = ({
  name,
  currentIdPage,
}: {
  name: string;
  currentIdPage: string;
}) => (
  <div className='overflow-y-auto'>
    <h2 className='mb-4 px-4 text-4xl font-bold'>{name}</h2>
    <LeagueNav currentIdPage={currentIdPage} />
  </div>
);
