import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LOADING_STATE,
  LoadingStateType,
} from 'shared/constants/loading-state';
import {
  addTournamentAsync,
  getTournamentsAsync,
  getOneTournamentAsync,
  editTournamentPointsAsync,
  refuseTournamentAsync,
} from './tournament-actions';
import type { TTournamentResponse } from 'store/apiTypes';

type TournamentSliceType = {
  status: LoadingStateType;
  list: TTournamentResponse[];
  current: TTournamentResponse | null;
  currentStatus: LoadingStateType;
  editPointsStatus: LoadingStateType;
};

const initialState: TournamentSliceType = {
  list: [],
  status: LOADING_STATE.UNKNOWN,
  current: null,
  currentStatus: LOADING_STATE.UNKNOWN,
  editPointsStatus: LOADING_STATE.UNKNOWN,
};

export const tournamentSlice = createSlice({
  name: 'tournament',
  initialState,
  reducers: {},
  extraReducers: {
    [addTournamentAsync.pending.type]: (state) => {
      state.status = LOADING_STATE.LOADING;
    },
    [addTournamentAsync.rejected.type]: (state) => {
      state.status = LOADING_STATE.ERROR;
    },
    [addTournamentAsync.fulfilled.type]: (
      state,
      action: PayloadAction<TTournamentResponse>,
    ) => {
      state.list = [...state.list, action.payload];
      state.status = LOADING_STATE.SUCCESS;
    },

    [getTournamentsAsync.pending.type]: (state) => {
      state.status = LOADING_STATE.LOADING;
    },
    [getTournamentsAsync.fulfilled.type]: (
      state,
      action: PayloadAction<Array<TTournamentResponse>>,
    ) => {
      state.list = action.payload;
      state.status = LOADING_STATE.SUCCESS;
    },

    [getOneTournamentAsync.pending.type]: (state) => {
      state.currentStatus = LOADING_STATE.LOADING;
    },
    [getOneTournamentAsync.fulfilled.type]: (state, action) => {
      state.current = action.payload;
      state.currentStatus = LOADING_STATE.SUCCESS;
    },
    [getOneTournamentAsync.rejected.type]: (state) => {
      state.current = null;
      state.currentStatus = LOADING_STATE.ERROR;
    },

    [editTournamentPointsAsync.pending.type]: (state) => {
      state.editPointsStatus = LOADING_STATE.LOADING;
    },
    [editTournamentPointsAsync.rejected.type]: (state) => {
      state.editPointsStatus = LOADING_STATE.ERROR;
    },
    [editTournamentPointsAsync.fulfilled.type]: (state) => {
      state.editPointsStatus = LOADING_STATE.SUCCESS;
    },

    [refuseTournamentAsync.fulfilled.type]: (state, action) => {
      console.log('refuseTournamentAsync.fulfilled.type', action);
      state.current = state.current
        ? {
            ...state.current,
            members:
              state.current?.members.filter(
                ({ member }) => member.serialNumber !== action.payload.memberId,
              ) || [],
          }
        : null;
    },
  },
});

export default tournamentSlice.reducer;
