import axios from 'axios';
import { API } from 'store/api/api';

export type AuthVerificationApiType = {
  authDate: number;
  firstName: string;
  hash: string;
  id: string;
  lastName: string;
  photoUrl: string;
  username: string;
};

/**
 * Проверка авторизации
 */
export const authVerificationApi = (data: AuthVerificationApiType) => {
  return axios.post(API.auth(), data);
};