import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { classname } from 'utils/bem-config';
import './MainPage.scss';
import bgImg from './bg_ball.jpg';
import bgBtn1 from './bg_btn_1.jpg';
import bgBtn2 from './bg_btn_2.jpg';
import { LoginIcon } from './login_16.js';
import logo from './logo-tennis.svg';
import { TgIcon } from './tg_16.js';
import { LayoutMain } from '../../components/layouts/layout-main';
import { useMedia } from 'utils/hooks/useMedia';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addOrgWithAdminAsyncAction } from 'store/features/organization/organization-actions';
import { memberMySelector } from 'store/features/member/member-selectors';
import { myOrgDataSelector } from 'store/features/organization/organization-selectors';

const bem = classname('MainPage');

const SideBlock = () => (
  <div className={bem('side')} style={{ backgroundImage: `url(${bgImg})` }}>
    <div>
      <img src={logo} alt='Playbook - большой теннис' />
    </div>
    <div className={bem('playbookName')}>PlayBook</div>
  </div>
);

const ButtonCategory = ({
  href = '',
  to = '',
  text = '',
  bg = '',
  cssMode = '',
  Icon = <LoginIcon />,
  onClick,
  state,
}: any) => {
  const Body = () => (
    <>
      <div className={bem('bcSide', { theme: cssMode })}>{Icon}</div>
      <div className={bem('bcBodySide')}>
        <span className={bem('bcBodyText')}>{text}</span>
      </div>
    </>
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (to) {
    return (
      <Link
        to={to}
        state={state}
        className={bem('ButtonCategory', { theme: cssMode })}
        style={{ backgroundImage: `url(${bg})` }}
        onClick={handleClick}
      >
        <Body />
      </Link>
    );
  }

  if (href) {
    return (
      <a
        style={{ backgroundImage: `url(${bg})` }}
        target='_blank'
        rel='noopener noreferrer'
        href={href}
        className={bem('ButtonCategory', { theme: cssMode })}
      >
        <Body />
      </a>
    );
  }

  return <></>;
};

const SideContent = () => {
  const dispatch = useAppDispatch();
  const memberMy = useAppSelector(memberMySelector);
  const myOrgData = useAppSelector(myOrgDataSelector);

  const onClick = useCallback(() => {
    if (memberMy && !myOrgData) {
      dispatch(
        addOrgWithAdminAsyncAction({
          name: `Playbook_common_${memberMy.serialNumber}`,
          memberSn: memberMy.serialNumber,
        }),
      );
    }
  }, [memberMy, myOrgData, dispatch]);

  return (
    <nav className='p-4'>
      <ButtonCategory
        text='Спарринг- партнёр'
        href='https://t.me/PlaybookTennis_bot?start=site_main'
        bg={bgBtn1}
        cssMode='blue'
        Icon={<TgIcon />}
      />
      <ButtonCategory
        to='/organizations'
        text='Турниры'
        bg={bgBtn2}
        cssMode='orange'
      />
      <ButtonCategory
        onClick={onClick}
        to={memberMy ? '/create-tournament' : '/login'}
        text='Создать турнир бесплатно'
        cssMode='transparent'
        state={{
          loginTitle: 'Войти, чтобы создать турнир',
          redirectTo: '/create-tournament',
        }}
      />
    </nav>
  );
};

export const MainPage: FC = () => {
  const { tabletLarge } = useMedia();

  return (
    <LayoutMain
      mainComponent={
        <>
          {!tabletLarge && <SideContent />}
          {tabletLarge && (
            <div className={bem()}>
              <SideBlock />
            </div>
          )}
        </>
      }
      sideBarComponent={<SideContent />}
      navNameTo=''
      navTo=''
      currentLocationName='Главная'
    />
  );
};
