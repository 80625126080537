// import { useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';

import {
  addOrgAsyncAction,
  viewsOrgAsyncAction,
} from 'store/features/organization/organization-actions';
import {
  viewsLeagueAsyncAction,
  addLeagueAsyncAction,
} from 'store/features/league/league-actions';
import { useAppDispatch } from 'store/hooks';
import { useEffect } from 'react';
// import { orgSelector } from 'store/features/organization/organization-selectors';
// import { leagueSelector } from 'store/features/league/league-selectors';

export const AddOrg = () => {
  const dispatch = useAppDispatch();

  /**
   * Создаем организацию
   */
  const onFinishOrgCreate = (values: any) => {
    dispatch(addOrgAsyncAction({ name: values.orgName }));
  };

  useEffect(() => {
    // Получаем организации с бэка
    dispatch(viewsOrgAsyncAction());
  }, [dispatch]);

  // Вытаскиваем все организации со store
  // const orgs = useSelector(orgSelector);
  // console.log('orgs', orgs);

  /**
   * Создаем Лигу
   */
  const onFinishLeagueCreate = (values: any) => {
    dispatch(
      addLeagueAsyncAction({
        name: values.leagueName,
        orgId: values.orgName,
        city: values.leagueCity,
      }),
    );
  };

  useEffect(() => {
    // Получаем лиги с бэка
    dispatch(viewsLeagueAsyncAction({ orgId: '1' }));
  }, [dispatch]);

  // Вытаскиваем все лиги со store
  // const league = useSelector(leagueSelector);
  // console.log('league', league);

  return (
    <div className='container overflow-auto'>
      {/* ORG */}
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        name='org'
        onFinish={onFinishOrgCreate}
        className='mb-10'
      >
        <Form.Item
          label='Название организации'
          name='orgName'
          rules={[{ required: true, message: 'Введите название организации' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Создать организацию
          </Button>
        </Form.Item>
      </Form>

      {/* LEAGUE */}
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        name='league'
        onFinish={onFinishLeagueCreate}
      >
        <Form.Item
          label='Номер организации'
          name='orgName'
          rules={[{ required: true, message: 'Введите номер организации' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Город Лиги'
          name='leagueCity'
          rules={[{ required: true, message: 'Введите город лиги' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Название Лиги'
          name='leagueName'
          rules={[{ required: true, message: 'Введите название лиги' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Создать лигу
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
