import { FC } from 'react';
import { classname } from 'utils/bem-config';
import { TgIcon } from './tg_16';
import { VkIcon } from './vk_16';
import { IconBot } from 'icons';

import './footer.scss';

const bem = classname('footer');

const SNLink = ({ Icon, link }: { Icon: React.FC; link: string }) => {
  return (
    <a
      className={bem('SNLink')}
      href={link}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Icon />
    </a>
  );
};

export const Footer: FC = () => {
  return (
    <footer className={bem()}>
      <small className={bem('copy')}>&copy; 2018-2024 PlayBook</small>
      <div className={bem('snLinks')}>
        <SNLink
          Icon={IconBot}
          link='https://t.me/PlaybookTennis_bot?start=site_footer'
        />
        <SNLink Icon={TgIcon} link='https://t.me/vadimistennis' />
        <SNLink Icon={VkIcon} link='https://vk.com/playbooksu' />
      </div>
    </footer>
  );
};
